.main-page{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 100vh;
    width: 100vw;
}

.ea-counters{
    display: flex;
    // overflow-x: scroll;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 100%;
}
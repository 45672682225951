h1{
    margin: 1.5ex;
}

.display{
    display: grid;
    gap: 2px;
    border: 10px solid grey;
    border-radius: 5px;
    background-color: grey;
    margin: 10px;
    width: fit-content;
    // max-width: 90vw;
    // max-height: 80vh;
    // overflow: hidden;
}

.white{
    background-color: white;
}

.black{
    background-color: black;
}
.ea-counter{
  margin: 2ex;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1ex;
}
.controls{
    margin: 10px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

/* CSS */
button {
  background-color: #FFFFFF;
  border: 1px solid #222222;
  border-radius: 8px;
  box-sizing: border-box;
  color: #222222;
  cursor: pointer;
  display: inline-block;
  font-family: Circular,-apple-system,BlinkMacSystemFont,Roboto,"Helvetica Neue",sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
  outline: none;
  padding: 5px 5px;
  position: relative;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  transition: box-shadow .2s,-ms-transform .1s,-webkit-transform .1s,transform .1s;
  user-select: none;
  -webkit-user-select: none;
  width: auto;
}

button:focus-visible {
  box-shadow: #222222 0 0 0 2px, rgba(255, 255, 255, 0.8) 0 0 0 4px;
  transition: box-shadow .2s;
}

button:active {
  background-color: #F7F7F7;
  border-color: #000000;
  transform: scale(.96);
}

button:disabled {
  border-color: #DDDDDD;
  color: #DDDDDD;
  cursor: not-allowed;
  opacity: 1;
}

// Number
input[type=number] {
	width: 3em;
	padding: 0.5em;
	border: .2em solid black;
	border-radius: 1em;
	text-align: center;
	color: black;
    background-color: white;
	appearance: textfield;
	margin: 0;
	&::-webkit-inner-spin-button {
		opacity: 1;
		background: black;
	}
}

.greyed-out{
    filter: opacity(0.5);
}


.animation-button-box{
  display: flex;
  justify-content: center;
  flex-direction: column;
}
